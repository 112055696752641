<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <business
        v-for="(item, i) in parentBusiness"
        :key="i + 'b'"
        :business="item"
        :alt-role="altRole"
        :is-super="isSuper"
      />
      <!--
      <warehouse
        v-for="(warehouse, i) in warehouses"
        :key="i + 'w'"
        :warehouses="warehouse"
      /> -->
      <!-- <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-twitter"
          title="Followers"
          value="+245"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="secondary"
          icon="mdi-poll"
          title="Website Visits"
          value="75.521"
          sub-icon="mdi-tag"
          sub-text="Tracked from Google Analytics"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="success"
          icon="mdi-store"
          title="Revenue"
          value="$ 34,245"
          sub-icon="mdi-calendar"
          sub-text="Last 24 Hours"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="orange"
          icon="mdi-sofa"
          title="Bookings"
          value="184"
          sub-icon="mdi-alert"
          sub-icon-color="red"
          sub-text="Get More Space..."
        />
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
  // import pageCondicionerMixin from '@/mixins/pageCondicionerMixin'
  import { SUPER_ADMIN } from '@/const'
  import Business from './components/dashboard/Business.vue'
  // import Warehouse from './components/dashboard/Warehouse.vue'
  import { mapState, mapMutations, mapActions } from 'vuex'

  export default {
    name: 'DashboardDashboard',

    components: {
      Business,
      // Warehouse,
    },

    data () {
      return {
        altRole: null,
      }
    },

    computed: {
      ...mapState(['user', 'parentBusiness']),
      isSuper () {
        return this.user?.authorization.filter(auth => auth.roleId === SUPER_ADMIN).length > 0
      },
      // business () {
      //   // // // // // // // // // // // // // // // // // // // // // // // // // // console.log(this.user?.authorization.filter(auth => auth.roleId === SUPER_ADMIN).length <= 0)
      //   // if (this.user?.authorization.filter(auth => auth.roleId === SUPER_ADMIN).length <= 0) {
      //   //   return this.user?.authorization
      //   // }

      //   if (this.user?.authorization.filter(auth => auth.roleId === SUPER_ADMIN).length > 0) {
      //     this.changeAltRole('Super Administrador')

      //     if (this.parentBusiness.length > 0) {
      //       return this.parentBusiness
      //     }

      //     this.fethBusinessParents()

      //     return this.parentBusiness
      //   } else if (this.user?.authorization.filter(auth => auth.roleId === GENERAL_MANAGER).length > 0) {
      //     this.changeAltRole('Gerente General')

      //     const id = this.user?.authorization.filter(auth => auth.roleId === GENERAL_MANAGER)[0].businessId

      //     if (this.parentBusiness.length > 0) {
      //       return this.parentBusiness
      //     }

      //     this.fethBusinessParentsById({ id: id })

      //     return this.parentBusiness
      //   }

      //   return this.user?.authorization
      // },
      // warehouses () {
      //   return this.user?.warehouses
      // },
    },

    created () {
      this.setActions(false)
      this.setEnvironment(false)
      if (this.isSuper) {
        this.fethBusinessParents()
      } else {
        this.$router.push('/business/childs/' + this.user?.businessId)
      }
    },

    methods: {
      ...mapMutations({
        setActions: 'SET_ACTIONS_BUSINESS',
        setEnvironment: 'SET_ACTIONS_ENVIRONMENT',
      }),
      ...mapActions(['getParentsBusiness', 'getParentsBusinessById']),

      async fethBusinessParents () {
        this.altRole = 'Super Administrador'
        await this.getParentsBusiness()
      },

      async fethBusinessParentsById (payload) {
        this.altRole = 'Gerente General'
        await this.getParentsBusinessById(payload)
      },

      changeAltRole (v) {
        this.altRole = v
      },
    },

  }
</script>
